import React from "react";

export const SellerRow = ({ order }) => {
  return (
    <tr className="">
      <td>
        {order["product"]["title"]} #{order["product"]["id"]} <br />
        <img
          src={order["product"]["thumbnail"]}
          className="img-fluid rounded-3"
          width={"100px"}
        />
      </td>
      <td>{order?.product?.variants[0]?.seller_commision}</td>
      <td>
        {order?.seller_modified_commission ??
          order?.product?.variants[0]?.seller_commision}
      </td>

      <td>{order?.quantity}</td>
    </tr>
  );
};
