import React from "react";
import { useTranslation } from "react-i18next";

const TableLabels = () => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");

  const roles_labels = {
    employee: [
      _("panel.productTitle"),
      _("panel.sysBeforeDiscount"),
      _("panel.sysAfterDiscount"),
      _("panel.quantity"),

    ],
    admin: [
      _("panel.productTitle"),
      _("panel.sysBeforeDiscount"),
      _("panel.sysAfterDiscount"),
      _("panel.sellerBeforeDiscount"),
      _("panel.sellerAfterDiscount"),
      _("panel.quantityPrice"),
      _("panel.quantity"),

    ],
    seller: [
      _("panel.productTitle"),
      _("panel.sellerBeforeDiscount"),
      _("panel.sellerAfterDiscount"),
      _("panel.quantity"),

    ],
    affiliate: [
      _("panel.productTitle"),
      _("panel.beforeDiscount"),
      _("panel.afterDiscount"),
      _("panel.quantity"),
    ],
  };

  return (
    <tr>
      {roles_labels[role].map((label, index) => {
        return <th>{label}</th>;
      })}
    </tr>
  );
};

export default TableLabels;
