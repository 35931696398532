import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import BarChartSellers from "../../../charts/BarChartSellers";
import { Bar } from "react-chartjs-2";
import { Table } from "react-bootstrap";
import { Helmet } from "react-helmet"; 
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";

const SellersOverview = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [data, handleData] = useState([]);
  const [highestSysCommission, setHighestSysCommission] = useState(0);
  const [highestSellerCommission, setHighestSellerCommission] = useState(0);
  const [highestMarketerCommission, setHighestMarketerCommission] = useState(0);
  const [highestSysCommissionName, setHighestSysCommissionName] = useState("");
  const [highestSellerCommissionName, setHighestSellerCommissionName] = useState("");
  const [highestMarketerCommissionName, setHighestMarketerCommissionName] = useState("");   

  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate:new Date(),
      key: 'selection'
    }
  });

  const get_sellers_overview = (startDate, endDate) => { 
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/reports/sellers-overview`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate ,
        end_date: endDate 
      },
    })
      .then((res) => { 
        handleData(res?.data?.sellers);
        handleLoader(false);
      })
      .catch((e) => { handleLoader(false); });
  };
 
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection; 
    get_sellers_overview(startDate, endDate);
  };
  useEffect(()=>{get_sellers_overview()},[slug , lang ])

  

  useEffect(() => {
    if (data.length > 0) {
      let maxSysCommission = Math.max(...data.map((item) => item.sys_commission));
      let maxSellerCommission = Math.max(...data.map((item) => item.seller_commission));
      let maxMarketerCommission = Math.max(...data.map((item) => item.marketer_commission));

      setHighestSysCommissionName(data.find((item) => item.sys_commission === maxSysCommission).seller_first_name + " " +
        data.find((item) => item.sys_commission === maxSysCommission).seller_last_name
      );
      setHighestSellerCommissionName(data.find((item) => item.seller_commission === maxSellerCommission).seller_first_name + " " +
        data.find((item) => item.seller_commission === maxSellerCommission).seller_last_name
      );
      setHighestMarketerCommissionName(data.find((item) => item.marketer_commission === maxMarketerCommission).seller_first_name + " " +
        data.find((item) => item.marketer_commission === maxMarketerCommission).seller_last_name
      );
      setHighestSysCommission(maxSysCommission);
      setHighestSellerCommission(maxSellerCommission);
      setHighestMarketerCommission(maxMarketerCommission);
    }
  }, [data]);

  const lineChartData = {
    labels: [_("panel.sys_commission"), _("panel.seller_commission"), _("panel.marketer_commission")],
    datasets: [
      {
        label: _("panel.highest_commissions"),
        data: [
          highestSysCommission,
          highestSellerCommission,
          highestMarketerCommission,
        ],
        fill: false,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
 

  return (
    <>
      <div
        className="flex-grow-1 stats-component"
        dir={lang === "ar" ? "rtl" : "ltr"}
      >
        <Helmet>
          <title>{_("panel.sellersOverview")}</title>  
        </Helmet>

        <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
          <Loader active={loading} />
          <div className="overflow-auto reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
            <h3 className="fs-2" style={{ color: "rgba(89, 198, 201, 1)" }}>
              {_("panel.sellersOverview")}
            </h3>
       
              <DateRangePickerComponent 
                state={state} 
                setState={setState}    
                applyDateRange={()=>applyDateRange()}
              />
           
          </div>
          <div className="row  d-flex align-items-center justify-content-center">
            <div
              className="col-12"
              style={{ position: "relative", overflowX: "auto"  ,  height:"700px"}}
            >
          
              {data.length > 0 && (
                <Table
                responsive
                dir={box_head_dir}
                className="reports-table text-nowrap text-center">
                  <thead>
                    <tr>
                      <th scope="col" style={{ fontSize: "16px" }}>
                        #
                      </th>
                      <th
                        scope="col"
                        style={{ fontSize: "16px" }}
                        className="text-center"
                      >
                        {_("home.fullname")}
                      </th>

                      <th
                        scope="col"
                        style={{ fontSize: "16px" }}
                        className="text-center">
                        {_("panel.totalQuantity")}
                      </th>
                      <th
                        scope="col"
                        style={{ fontSize: "16px" }}
                        className="text-center">
                        {_("panel.marketer_commission")}
                      </th>
                      <th
                        scope="col"
                        style={{ fontSize: "16px" }}
                        className="text-center">
                        {_("panel.seller_commission")}
                      </th>
                      <th
                        scope="col"
                        style={{ fontSize: "16px" }}
                        className="text-center">
                        {_("panel.sys_commission")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 && data?.map((seller, index) => (
                      <tr key={seller.seller_id}>
                        <th scope="row" style={{ fontSize: "16px" }}>
                          {index + 1}
                        </th>
                        <td
                          className="text-center"
                          style={{ fontSize: "16px" }}
                        >
                          <span className="fw-bold"> # {seller.seller_id}</span>{" "}
                          <br />
                          {seller.seller_first_name} {seller.seller_last_name}
                        </td>

                        <td
                          className="text-center"
                          style={{ fontSize: "16px" }}
                        >
                          {seller.quantity}
                        </td>
                        <td
                          className="text-center"
                          style={{ fontSize: "16px" }}
                        >
                          { seller.marketer_commission.toLocaleString()}
                        </td>
                        <td
                          className="text-center"
                          style={{ fontSize: "16px" }}
                        >
                          { seller.seller_commission.toLocaleString()}
                        </td>
                        <td
                          className="text-center"
                          style={{ fontSize: "16px" }}
                        >
                          { seller.sys_commission.toLocaleString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>    
              )}
            </div>
            <div className="row">
              <h3 className="text-center fw-bold ">
                {" "}
                {_("panel.highest_commissions")}
              </h3>
              <div className="col-lg-8 gy-4 mb-4 pb-4  ">
                <Bar data={lineChartData} options={options} />
              </div>{" "}
              <div className="col-lg-4 gy-4 mb-4 pb-4  d-flex justify-content-center flex-column">
                <h4>
                  {_("panel.highest_marketer_commission")} :
                  {highestMarketerCommissionName}
                </h4>
                <h4>
                  {" "}
                  {_("panel.highest_seller_commission")}:
                  {highestSellerCommissionName}
                </h4>
                <h4>
                  {" "}
                  {_("panel.highest_sys_commission")} :
                  {highestSysCommissionName}
                </h4>
              </div>
            </div>
          </div>
          <hr />           
          <div className="row mt-4">

            <BarChartSellers   />
              
          </div>
        </div>
      </div>
    </>
  );
};

export default SellersOverview;
