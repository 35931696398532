import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import IconsGen from "../../../icons_generator/IconsGen";
import ProtectedSidebarRoute from "../../../authentication/ProtectedSidebarRoute";
import Roles from "../../../authentication/Roles";
import AddProduct from "./AddProduct";
import Favorites from "./Favorites";
import EmployeeTypes from "../../../authentication/EmployeeTypes";
import Exclusive from "./Exclusive";
import Sizes from "./Sizes";
import Colors from "./Colors";
import Offers from "./Offers";

const Products = ({ direction }) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");
  const [isSubNavVisible, setIsSubNavVisible] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("/panel/products/")) {
      setIsSubNavVisible(true);
    } else {
      setIsSubNavVisible(false);
    }
  }, [location.pathname]);
  const handleClick = () => {
    setIsSubNavVisible(!isSubNavVisible);
  };
  return (
    <div className="links-container d-flex flex-column">
      <div
        className={`feature_link ${
          lang === "en" ? "feature_link_en" : ""
        } d-flex flex-row-reverse align-items-center gap-2 py-3
                ${isSubNavVisible ? "active-link" : ""}`}
        dir={direction}
        // to={`/${slug}/${lang}/panel/products/`}
        onClick={handleClick}
      >
        <IconsGen icon_name={"products"} />
        <p>{_("panel.products")}</p>
      </div>
      <ul
        className={`sub-nav flex-column gap-2 px-5 pt-3 ${
          isSubNavVisible ? "d-flex" : "d-none"
        }`}
      >
        <li>
          <NavLink to={`/${slug}/${lang}/panel/products/all-products`}>
            <p className="d-inline ">{_("panel.allProducts")}</p>
          </NavLink>
        </li>
        {/* <li>
          <NavLink to={`/${slug}/${lang}/panel/products/suggest-products`}>
            <p className="d-inline ">{_("panel.suggestProducts")}</p>
          </NavLink>
        </li> */}
        <ProtectedSidebarRoute
          component={Favorites}
          role={role}
          roles={[Roles["AFFILIATE"]]}
          direction={direction}
          slug={slug}
          lang={lang}
        />
        <ProtectedSidebarRoute
          component={AddProduct}
          role={role}
          roles={[Roles["SELLER"]]}
          direction={direction}
          slug={slug}
          lang={lang}
        />
        <ProtectedSidebarRoute
          component={AddProduct}
          role={role}
          roles={[Roles["EMPLOYEE"]]}
          direction={direction}
          empType={employee_type}
          empTypes={[
            EmployeeTypes["PRODUCTS_EMPLOYEE"],
            EmployeeTypes["WAREHOUSE_EMPLOYEE"],
          ]}
          slug={slug}
          lang={lang}
        />
        <ProtectedSidebarRoute
          component={Sizes}
          role={role}
          roles={[Roles["EMPLOYEE"]]}
          direction={direction}
          empType={employee_type}
          empTypes={[
            EmployeeTypes["PRODUCTS_EMPLOYEE"],
            EmployeeTypes["WAREHOUSE_EMPLOYEE"],
          ]}
          slug={slug}
          lang={lang}
        />
        <ProtectedSidebarRoute
          component={Colors}
          role={role}
          roles={[Roles["EMPLOYEE"]]}
          direction={direction}
          empType={employee_type}
          empTypes={[
            EmployeeTypes["PRODUCTS_EMPLOYEE"],
            EmployeeTypes["WAREHOUSE_EMPLOYEE"],
          ]}
          slug={slug}
          lang={lang}
        />
        <ProtectedSidebarRoute
          component={AddProduct}
          role={role}
          roles={[Roles["ADMIN"], Roles["FULFILLMENT"]]}
          direction={direction}
          slug={slug}
          lang={lang}
        />
        <ProtectedSidebarRoute
          component={Sizes}
          role={role}
          roles={[Roles["ADMIN"]]}
          direction={direction}
          slug={slug}
          lang={lang}
        />
        <ProtectedSidebarRoute
          component={Colors}
          role={role}
          roles={[Roles["ADMIN"]]}
          direction={direction}
          slug={slug}
          lang={lang}
        />
        <ProtectedSidebarRoute
          component={Offers}
          role={role}
          roles={[Roles["ADMIN"], Roles['AFFILIATE'], Roles['SELLER']]}
          direction={direction}
          slug={slug}
          lang={lang}
        />
        {role === "admin" ||
        role === "affiliate" ||
        employee_type == "confirmation employee" ||
        employee_type == "operation employee" ? (
          <Exclusive slug={slug} lang={lang} />
        ) : null}
      </ul>
    </div>
  );
};

export default Products;
