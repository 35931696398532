import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader";
import LevelsAddAndUpdate from "../../../modals/LevelsAddAndUpdate";
import LevelsTable from "./LevelsTable";
import { Helmet } from "react-helmet";

const Levels = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, handleLoader] = useState(false);
  const fetchLevels = () => {
    AxiosController.get(`api/${slug}/${lang}/level/get-levels`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleLoader(false);
        setData(res.data);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
  useEffect(() => {
    fetchLevels();
  }, []);
  return (
    <Container style={{ position: "relative" }}>
      <Helmet>
        <title>{_("panel.levelAffiliate")}</title>
      </Helmet>
      <Loader active={loading} />
      <LevelsAddAndUpdate
        show={show}
        setShow={setShow}
        update={false}
        fetchLevels={() => fetchLevels()}
      />
      <div className="add-product-container d-flex flex-column gap-2 my-3 px-4">
        <div className="add-header" dir={lang === "en" ? "ltr" : "rtl"}>
          <h2 className="mb-3" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.levelAffiliate")}
          </h2>
        </div>
        <div
          className="d-flex gap-5 flex-column-reverse flex-md-row"
          dir={lang === "en" ? "ltr" : "rtl"}
        >
          <Button
            variant="primary"
            onClick={() => {
              setShow(true);
            }}
            style={{ background: "#59C6C9", color: "white", border: "none" }}
          >
            {_("panel.addLevel")}
          </Button>
        </div>

        <LevelsTable data={data} fetchLevels={() => fetchLevels()} />
      </div>
    </Container>
  );
};

export default Levels;
