import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";

import TableLabels from "./TableLabels";
import IconsGen from "../../../icons_generator/IconsGen";
import { SellerRow } from "./SellerRow";
const colors = {
  seller: "rgba(89, 198, 201, 1)",
  affiliate: "rgb(243, 168, 55)",
  employee: "rgba(23, 43, 77, 1)",
};
const PaymentRequestsTableRow = ({ payments_list, handleLoader }) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");
  useEffect(() => {}, [payments_list]);
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table
        className="table table-borderless text-nowrap"
        htmlFor="flexCheckDefault"
      >
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.map((order, index) => {
            return role == "seller" ? (
              <SellerRow order={order} />
            ) : (
              <tr className="">
                <td>
                  {order["product"]["title"]} #{order["product"]["id"]} <br />
                  <img
                    src={order["product"]["thumbnail"]}
                    className="img-fluid rounded-3"
                    width={"100px"}
                  />
                </td>
                <td>
                  {order?.product?.variants[0]?.sys_commision +
                    (role != "admin"
                      ? order?.product?.variants[0]?.seller_commision
                      : 0)}
                </td>
                <td>
                  {order?.sys_modified_commission +
                    (role != "admin"
                      ? order?.seller_modified_commission ??
                        order?.product?.variants[0]?.seller_commision
                      : 0)}
                </td>
                {role == "admin" ? (
                  <>
                    <td>{order?.product?.variants[0]?.seller_commision}</td>
                    <td>
                      {order?.seller_modified_commission ??
                        order?.product?.variants[0]?.seller_commision}
                    </td>
                    <td>
                      {order?.quantity *
                        ((order?.seller_modified_commission ??
                          order?.product?.variants[0]?.seller_commision) +
                          order?.sys_modified_commission)}
                    </td>
                  </>
                ) : (
                  ""
                )}
                <td>{order?.quantity}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentRequestsTableRow;
