import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import IconsGen from "../../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  Title,
  LinearScale,
  CategoryScale,
} from "chart.js";
import PieChartTemplate from "../../../charts/ChartTemplate";
import BarChartTemplate from "../../../charts/BarTemplate";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";

ChartJS.register(
  CategoryScale,
  Title,
  BarElement,
  LinearScale,
  ArcElement,
  Tooltip,
  Legend
);

const charts_data = (
  slug,
  lang,
  token,
  topOrdersStatusHandler,
  topCountriesStatusHandler,
  topProductsHandler,
  handleLoader,
  dates = {}
) => {
  AxiosController.get(`api/${slug}/${lang}/get-control-panel-stats/`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      start: dates["start"] ? dates["start"] : "",
      end: dates["end"] ? dates["end"] : "",
    },
  })
    .then((res) => {
      topOrdersStatusHandler(
        new PieChartTemplate(res.data.marketer_most_status_orders, "status")
      );
      topCountriesStatusHandler(
        new PieChartTemplate(
          res.data.success_orders_across_currencies,
          "country",
          false
        )
      );
      topProductsHandler(
        new BarChartTemplate(res.data.marketer_most_success_products)
      );
      handleLoader(false);
    })
    .catch((e) => {});
};

const ChartsStats = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const [topOrdersStatus, topOrdersStatusHandler] = useState(
    new PieChartTemplate([], [100])
  );
  const [topCountriesStatus, topCountriesStatusHandler] = useState(
    new PieChartTemplate([], [100])
  );
  const [topProducts, topProductsHandler] = useState(new BarChartTemplate([]));
  const [loading, handleLoader] = useState(false);

  const token = localStorage.getItem("token");

  const box_head_dir = lang === "en" ? "ltr" : "rtl";

 
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate:new Date(),
      key: 'selection'
    }
  });
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection; 
    handleLoader(true);
    charts_data(
      slug,
      lang,
      token,
      topOrdersStatusHandler,
      topCountriesStatusHandler,
      topProductsHandler,
      handleLoader,
      { start: startDate, end: endDate } );
  };
  useEffect(() => {
    handleLoader(true);
    charts_data(
      slug,
      lang,
      token,
      topOrdersStatusHandler,
      topCountriesStatusHandler,
      topProductsHandler,
      handleLoader
    );
  }, [
    slug,
    lang,
    token,
    topOrdersStatusHandler,
    topCountriesStatusHandler,
    topProductsHandler,
    handleLoader,
  ]);

  return (
    <div className="position-relative">
      <Loader active={loading} />
      <div className="stats-container mt-2">
        <div
          className="reports-header d-flex flex-column  flex-md-row py-2 justify-content-between flex-wrap"
          dir={box_head_dir}
        >
          <h3 style={{ color: "rgba(23, 43, 77, 1)" }}>
            {_("panel.orderStatus")}
          </h3>
          <div className="interval d-flex flex-column gap-2 flex-md-row-reverse align-items-center">
            <div className="form-group">
            <DateRangePickerComponent 
                state={state} 
                setState={setState}    
                applyDateRange={()=>applyDateRange()}
              />
        
            </div>
          </div>
        </div>
        <div
          className="piechart-container d-flex align-items-center py-4 px-4 flex-column flex-md-row my-3"
          style={{ overflow: "hidden" }}
          dir={box_head_dir}
          
        >
     <div className="container">
  <div className="row">
    {topOrdersStatus["datasets"][0]["data"].length
      ? topOrdersStatus["datasets"][0]["data"].map((data, index) => (
          <div className="col-12 col-lg-4 mb-4">
            <div className="info-container d-flex gap-3 align-items-center">
              <div
                className="green-box"
                style={{
                  backgroundColor: topOrdersStatus["datasets"][0]["data"]
                    .length
                    ? topOrdersStatus["datasets"][0]["backgroundColor"][index]
                    : "transparent",
                }}
              ></div>
              <div className="text-container">
                <p>
                  {_(
                    `panel.${
                      topOrdersStatus["titles"].length
                        ? topOrdersStatus["titles"][index]
                        : "test"
                    }`
                  )}
                </p>
                <p>
                  {topOrdersStatus["datasets"][0]["data"].length
                    ? topOrdersStatus["datasets"][0]["data"][index]
                    : "0"}
                </p>
              </div>
            </div>
          </div>
        ))
      : ""}
  </div>
</div>

          <div className="chart-container">
            <Pie
              data={
                topOrdersStatus["datasets"][0]["data"].length
                  ? topOrdersStatus
                  : topOrdersStatus
              }
              redraw={true}
            />
          </div>
        </div>
      </div>
      <div
        className="stats-flex d-flex flex-column flex-lg-row  justify-content-between  gap-3"
        style={{ width: "100%" }}
      >
        <div
          className="d-flex flex-column gap-2  px-3 py-2"
          style={{ width: "100%", flexGrow: "2" }}
          dir={box_head_dir}
        >
          <p className="fs-4" style={{ color: "rgba(23, 43, 77, 1)" }}>
            {_("panel.topcountries")}
          </p>
          <div className="d-flex flex-md-row-reverse flex-column align-items-center">
            <div style={{ width: "250px" }}>
              <Doughnut
                data={
                  topCountriesStatus["datasets"][0]["data"].length
                    ? topCountriesStatus
                    : topCountriesStatus
                }
                redraw={true}
              />
            </div>
            <div className="right-side d-flex flex-column gap-4 flex-grow-1">
            {topCountriesStatus["datasets"][0]["data"].length ? (
                topCountriesStatus["datasets"][0]["data"].map((value, index) => {
                    return (
                        <div className="info-container  d-flex  gap-3 align-items-center">
                        <div
                          className="green-box"
                          style={{ backgroundColor: topCountriesStatus["datasets"][0][
                            "data"
                          ].length
                            ? topCountriesStatus["datasets"][0]["backgroundColor"][
                                index
                              ]
                            : "transparent" }}
                        ></div>
                        <div className="text-container">
                          <p>
                            {topCountriesStatus["titles"].length
                              ? topCountriesStatus["titles"][index]
                              : "test"}
                          </p>
                          <p>
                            {topCountriesStatus["datasets"][0]["data"].length
                              ? topCountriesStatus["datasets"][0]["data"][index]
                              : "0"}
                          </p>
                        </div>
                      </div>
                    );
                })
            ) : ''}

            </div>
          </div>
        </div>
        <div
          className="d-flex flex-column gap-2 flex-grow-1 px-3 py-2"
          style={{ width: "100%" }}
          dir={box_head_dir}
        >
          <p className="fs-4" style={{ color: "rgba(23, 43, 77, 1)" }}>
            {_("panel.topproducts")}
          </p>
          <div className="d-flex">
            <Bar
              data={
                topProducts["datasets"][0]["data"].length
                  ? topProducts
                  : topProducts
              }
              redraw={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartsStats;
